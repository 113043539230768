// --------------------------------------------------
// State
// --------------------------------------------------

.top-bar-container, .top-bar-container-single {

  background: $white;
  border-bottom: 1px solid darken($light-gray, 5%);
  .menu-icon {
    @include hamburger($primary-color, smart-scale($primary-color, 50%, 20%), 24px, 20px, 2px, 3);
  }
  .logo {
    display: block;
    path.blue {fill: $primary-color;}
    path.green {fill: $secondary-color;}
  }
  .menu.main-menu {
    a {
      color: $primary-color;

    }
  }
  .menu.mobile-menu {
    a {
      color: $white;
    }
  }
  .inverted & {
    background:none;
    border-bottom: 0;
    padding-top:20px;
    .menu-icon {
      @include hamburger($white, smart-scale($primary-color, 50%, 20%), 24px, 20px, 2px, 3);
    }
    .logo {
      path {fill: #fff;}
    }
    .menu.main-menu {
      a {color: #fff;}
    }
  }
}
