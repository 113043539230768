// --------------------------------------------------
// Your module
// --------------------------------------------------
.top-bar {
  &-container, &-container-single {
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 100;
  }
}
.top-bar-title {

    @include clearfix;
    a {
      padding-left:0;
    }
    a,div {
      display: block;
      float: left;
      padding: 0.45rem 0 0 0;
    }
    div {
      width:24px;
      padding-left:1rem;
    }

  &.mobile-menu {
    background-color: inherit;
  }
  &.no-menu {
    @include breakpoint(medium down) {
      width:80%;
    }
  }
}
.home-large-hero {

  height: 100vh;
  width:100%;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    width:100%;
    height:100%;
    background: rgba($secondary-color, 0.3);
    z-index: -1;
  }

    video#videobg {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -1;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      background: $primary-color url(../video/videobg.jpg) no-repeat center center / cover;
    }
  @include breakpoint(medium down) {
    background: $primary-color url(../video/videobg.jpg) no-repeat center center / cover;
    video#videobg {
      display: none;
    }

  }
  &.util {
    &:after {
      background: none;
    }
    .site-main-header {
      color: $primary-color;
    }
  }

}

.service {
  margin-bottom: 1.5rem;

  &-icon {
    width:80px;
    float: left;

  }
  &-description {

    padding-left:110px;
    h3 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: rem-calc(16);
      line-height: 1;
      margin-bottom:0.25em;
      letter-spacing: 0.1em;

    }
    p {
      font-family: $mono-font;
      font-size: rem-calc(16);
      line-height: 1.2;
    }
  }
}
.projects-list {
  @include clearfix;
  .column {
    margin-bottom: map-get($grid-column-responsive-gutter, medium);
  }
  figure {
    overflow: hidden;
    margin:0;
    position: relative;
    img {
      transition: transform 0.4s ease 0s;
      position: relative;
      display: block;
    }
    figcaption {
      padding:20px 0;
      width: 100%;    
      text-align: left; 
      transition: all 0.2s ease ;
    }
    &:hover, &:focus {     
     background: $white;
     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
     figcaption {
       padding: 20px;
       }      
    }
  }

  .project-title {
    //@include vertical-center;
    color: $black;
    font-size: rem-calc(20);
    font-weight: bold;
  }

  a:hover {
    .project-thumb {
      opacity: 1;
    }
  }
}
.clients-list {
  .column {
    margin-bottom: map-get($grid-column-responsive-gutter, medium);
  }
  img {
    filter: grayscale(100%) ;
  }
}
.project-title {
  font-size: rem-calc(36);
  line-height: 1.1;
}
.project-description {
  padding:4.5rem 0;
  &-text {
    font-size: rem-calc(18);

  }
}
.project-slideshow-container {
  position: relative;
}
.project-image-container {
  margin:7px;
  img {
    margin:0 auto;
  }
}
.project-client {
  font-family: $mono-font;
  margin: 1.5rem 0;

  span {
    font-family: $body-font-family;
    font-size: rem-calc(14);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {

  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin:0.5rem 0 0 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: 1;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content:"•";
        width: 20px;
        height: 20px;
        font-size:1.5rem;
        line-height: 20px;
        text-align: center;
        color: $dark-gray;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $primary-color;
      opacity: 0.75;
    }
  }
}
.slideshow-nav {
  .prev-button, .next-button {
    background-color: rgba($black, 0.5);
    background-repeat: no-repeat;
    background-size: 24px 24px;

    width:48px;
    height:48px;
    border-radius:50%;
    @include vertical-center;
    z-index: 10;
    path { fill: $white;}
    &:hover {
      background-color: rgba($black, 0.9);
    }

  }
  .prev-button {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2084.86%20148.87%22%20%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M59.34%2C79.43L120.8%2C18a3.61%2C3.61%2C0%2C0%2C0%2C0-5.11L114%2C6.05a3.61%2C3.61%2C0%2C0%2C0-5.11%2C0L38.06%2C76.88a3.61%2C3.61%2C0%2C0%2C0%2C0%2C5.11l70.82%2C70.82a3.61%2C3.61%2C0%2C0%2C0%2C5.11%2C0L120.8%2C146a3.61%2C3.61%2C0%2C0%2C0%2C0-5.11Z%22%20transform%3D%22translate%28-37%20-4.99%29%22/%3E%3C/svg%3E);
    background-position: 9px center;
    left:30px;
  }
  .next-button {
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2084.86%20148.87%22%20%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M46.29%2C140.89a3.61%2C3.61%2C0%2C0%2C0%2C0%2C5.11l6.81%2C6.81a3.61%2C3.61%2C0%2C0%2C0%2C5.11%2C0L129%2C82a3.61%2C3.61%2C0%2C0%2C0%2C0-5.11L58.21%2C6.05a3.61%2C3.61%2C0%2C0%2C0-5.11%2C0l-6.81%2C6.81a3.61%2C3.61%2C0%2C0%2C0%2C0%2C5.11l61.46%2C61.46Z%22%20transform%3D%22translate%28-45.23%20-4.99%29%22/%3E%3C/svg%3E);
    background-position: 13px center;
    right:30px;
  }
}
.post-nav-links {
  padding:0 0 3rem 0;
  .menu {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 50%;
    display: block;
    > a {
      padding:2rem;
      height:100%;
      &:hover {
        background: $light-gray;
      }
    }
  }
  a {
    border: 1px solid $light-gray;
    color: $primary-color;
    font-size:rem-calc(18);

    span {
      @include breakpoint(small only) {
      display: none;
      }
      display: block;
    }
    small {
      font-family: $mono-font;
      font-size: rem-calc(12);
      font-style: normal;
      letter-spacing: 0.1em;
      display: block;
      text-transform: uppercase;
      font-weight:bold;
      margin-bottom:0.5em;
    }
  }
}
