@keyframes bgGradient {
  0% {
    background-position: 50% 0%;
    opacity: 0.2;

  }
  25% {
    background-position: 25% 100%;
    opacity: 0.5;
  }
  50% {
    background-position: 50% 100%;
    opacity: 0.8;
  }
  75% {
    background-position: 75% 100%;
    opacity: 0.5;
  }
  100% {
    background-position: 50% 0%;
    opacity: 0.2;
  }
}
