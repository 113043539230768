// --------------------------------------------------
// Theme
// --------------------------------------------------
.main-header-container {
  @include vertical-center;
  width: 100%;
}

.site-main-header {
  color: #fff;
  font-size: rem-calc(40);
  font-weight: 800;
 // font-style: italic;
  line-height: 1.2;
  @include breakpoint(medium) {
    font-size: rem-calc(60);
  }
  @include breakpoint(large) {
    font-size: rem-calc(72);
  }
}

.menu.main-menu {
  a {
    font-weight: bold;
    text-transform: uppercase;
    font-size: rem-calc(14);
    letter-spacing: 0.15em;
  }
}

.menu-icon {
  margin-top: -7px;
}

.top-bar .mobile-menu {
  background: $primary-color;
  border-bottom: 1px solid darken($primary-color, 10%);
  transition: all 0.3s ease-in;
}

.title-bar-title {
  font-size: rem-calc(9);
  text-transform: uppercase;
}

.scroll-button {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 30px;
  left: 50%;
  margin-left: -20px;
  path {
    fill: #fff;
  }
}

.split-screen {

  @include breakpoint(medium up) {
    //background: linear-gradient( to right, $white 0%, $white 50%, $secondary-color 50%, $secondary-color 100%);
    position: relative;
    &:after {
      content:"";
      width:2px;
      height:100%;
      position: absolute;
      left:50%;
      top:0;
      margin-left:-1px;
      background: $light-gray;
    }
  }

}

.approach p {


  &.lead {

    font-size: 1.25rem;
    line-height: 1.5;
  }

}

.services {
  color: $primary-color;
  @include breakpoint(small only) {
    border-top: 2px solid $primary-color;
    padding-top: 2rem;
  }
  @include breakpoint(large) {
    padding-left: 100px;
  }
}

.approach {
  @include breakpoint(large) {
    padding-right: 100px;
  }
}

.work,
.contact {
  background: $light-gray;
}

.service-icon {
  path {
    fill: $secondary-color;
  }
}

label,
legend,
.contact button {

  font-weight: bold;
  color: $primary-color;

}

.contact {
  .button {
    text-transform: uppercase;
    font-size: 1rem;
  }
  .callout.alert {
    color: $alert-color;
    font-family: $mono-font;
  }
}

label {
  small {

    font-size: rem-calc(10);
    text-transform: uppercase;
    color: $alert-color;
  }
  small, .form-error {
    font-family: $mono-font;
  }
  .form-error {
    font-weight: normal;
  }
}

.back-link,
.url {
  font-family: $mono-font;
}
.button.follow {
  border-color: $secondary-color;
  color: $secondary-color;
  margin-top:1.5rem;
  font-weight: bold;
  font-size: 1rem;
  &:hover {
    border-color: $primary-color;
    color: $primary-color;

  }

}

.back-link{
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0.7rem !important;
  overflow: hidden;
  top:3px;

  &:hover {
    &::before, &::after {
      background: $medium-gray;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -2px;
    background: $primary-color;
  }
  &::before {
    transform:rotate(45deg);
  }
  &::after {
    transform:rotate(-45deg);
  }

  &.rounded {
    &::before, &::after {
      border-radius: 4px;
    }
  }

}

.bg-init,
.animsition-overlay-slide {
  background-color: $secondary-color;
  z-index: 500;
}

.animated {
  animation-duration: 0.2s;
  transition-timing-function: ease;
  animation-fill-mode: both;
}
.is_exiting,
.bg-init,
.loading-container,
.loading, .is_loading {
  background: $secondary-color;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading img {
  @include absolute-center;
}

.scene-element {
  animation-duration: 0.25s;
  transition-timing-function: ease-in;
}

.fadein {
  animation-name: fadeIn;
}

.fadeinup {
  animation-name: fadeInUp;
}

.fadeinright {
  animation-name: fadeInRight;
}
.slideinup {
  animation-name: slideInUp;
}
.slideindown {
  animation-name: slideInDown;
}

scene-element--delayed {
  animation-delay: 0.25s;
}

.is-exiting .scene-element {
  animation-direction: alternate-reverse;
}




.is-loading,
.is-loading a {
  cursor: progress;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
