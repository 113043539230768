// --------------------------------------------------
// Headings
// --------------------------------------------------
.site-main-header {
  font-family: $body-font-family;
}
.section-heading {

  font-size: rem-calc(32);
  font-weight: bold;
  margin-bottom: rem-calc(48);

}
