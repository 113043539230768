// --------------------------------------------------
// Layout default
// --------------------------------------------------
.top-bar {
  @include grid-row;
  &-left{

  }
  &-right {

    .menu {
      @include breakpoint(medium) {
       float: right;
      }
    }
  }
}
.logo {
  width: 240px;
  path {
    fill:#fff;
  }
}
.wrapper {
  position: relative;
}
footer {
 // border-top:1px solid $light-gray;
  padding: 1.5rem 0;
  font-size: rem-calc(14);
  font-family: $mono-font;
  p {

  }
}
