// --------------------------------------------------
// Config
// --------------------------------------------------

$body-font-family: "Avenir W01", arial, sans-serif;
$mono-font: "Consolas W01 Mono", "Cumberland W01", courier, monospaced;
//
// Colors
// --------------------------------------------------
$primary-color: #00355f;
$secondary-color: #1ec5a0;
$light-gray: #e5eeee;
$medium-gray: #c6cece;
$dark-gray: #a1a7a7;
$black: #2b3346;
$white: #fff;
$body-font-color: $primary-color;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
// $breakpoint-classes: (small medium large);

//Topbar
$topbar-background: transparent;

//Basic Typography
$anchor-color: $secondary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$global-font-size: 100%;
$lead-font-size: $global-font-size * 1.5;
$paragraph-lineheight: 1.5;

// 19. Forms
// ---------

// $fieldset-border: 1px solid $medium-gray;
// $fieldset-padding: rem-calc(20);
// $fieldset-margin: rem-calc(18 0);
// $legend-padding: rem-calc(0 3);
$form-spacing:1.25rem;
// $helptext-color: #333;
// $helptext-font-size: rem-calc(13);
// $helptext-font-style: italic;
// $input-prefix-color: $black;
// $input-prefix-background: $light-gray;
// $input-prefix-border: 1px solid $medium-gray;
// $input-prefix-padding: 1rem;
// $form-label-color: $black;
$form-label-font-size: rem-calc(16);
// $form-label-font-weight: $global-weight-normal;
// $form-label-line-height: 1.8;
// $select-background: $white;
// $select-triangle-color: #333;
// $select-radius: $global-radius;
$input-color: $primary-color;
// $input-font-family: inherit;
// $input-font-size: rem-calc(16);
$input-background: $white;
// $input-background-focus: $white;
// $input-background-disabled: $light-gray;
// $input-border: 1px solid $medium-gray;
// $input-border-focus: 1px solid $dark-gray;
$input-shadow: inset 0 0px 1px rgba($black, 0.1);
$input-shadow-focus: 0 0 0px $medium-gray;
// $input-cursor-disabled: default;
// $input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
// $input-number-spinners: true;
// $input-radius: $global-radius;
