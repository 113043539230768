// --------------------------------------------------
// Layout index page
// --------------------------------------------------

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section {
  padding-top: rem-calc(96);
  padding-bottom: rem-calc(96);
}
